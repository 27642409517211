import { For, createEffect, createSignal } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Link, Picture } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconMapPin } from '@troon/icons';
import { gql } from '../graphql';
import { ImageOverlay } from './image-overlay';
import { Content } from './content';
import type { ParentProps } from 'solid-js';
import type { HeroCarouselFragment } from '../graphql';

type Props = ParentProps & {
	facilities: Array<HeroCarouselFragment>;
};

export function HeroCarousel(props: Props) {
	// let timeout: NodeJS.Timeout;
	let prevTimeout: NodeJS.Timeout;

	const [running /* setRunning */] = createSignal(!isServer);
	const [current, setCurrent] = createSignal(0);
	const [prev, setPrev] = createSignal(props.facilities.filter((f) => f.metadata?.hero).length - 1);

	createEffect(() => {
		if (props.facilities.length && running()) {
			const curr = current();
			/* timeout = */ setTimeout(() => {
				clearTimeout(prevTimeout);
				setPrev(curr);
				setCurrent(curr + 1 >= props.facilities.filter((f) => f.metadata?.hero).length ? 0 : curr + 1);
			}, 6000);
		}
	});

	// Little hack to set the prewvious image to 0% opacity after it has faded out
	createEffect(() => {
		if (prev() >= 0) {
			prevTimeout = setTimeout(() => {
				setPrev(-1);
			}, 1500);
		}
	});

	return (
		<div
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="relative flex max-h-min min-h-[70svh] flex-col items-center overflow-hidden bg-brand md:min-h-[75svh]"
		>
			<For each={props.facilities}>
				{(facility, index) =>
					facility.metadata?.hero?.url ? (
						<div
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class={twJoin(
								'absolute size-full transition-all duration-[1500ms]',
								index() !== current() && index() !== prev() && 'z-0 opacity-0',
								index() === prev() && 'delay-200',
								index() === current() && 'z-20 opacity-100',
							)}
						>
							<Picture
								src={facility.metadata.hero.url}
								alt={`Photo of the golf course at ${facility.name}`}
								sizes={[
									[480, 560],
									[960, 761],
									[1600, 800],
								]}
								width="100%"
								height="100%"
								class="absolute size-full bg-center object-cover"
								loading={index() <= 1 ? 'eager' : 'lazy'}
							/>
						</div>
					) : null
				}
			</For>
			<div class="z-30 flex w-full grow items-center pb-12 pt-32">{props.children}</div>

			<Content class="relative z-30 mb-20 flex size-full flex-col items-end justify-end">
				<div aria-hidden class="relative text-sm text-white">
					<For each={props.facilities}>
						{(facility, index) => (
							<Link
								href={`/course/${facility.slug}`}
								aria-current={index() === current()}
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class={twJoin(
									'absolute bottom-0 right-0 overflow-hidden text-ellipsis text-nowrap text-end transition-all duration-[1500ms] hover:underline',
									index() !== current() && 'z-0 opacity-0',
									index() === current() && 'z-20 opacity-100',
								)}
							>
								<IconMapPin /> {facility.name}
							</Link>
						)}
					</For>
				</div>
			</Content>

			<div class="absolute inset-0 z-20 bg-hero" />
			<ImageOverlay />
		</div>
	);
}

export const fragment = gql(`
fragment HeroCarousel on Facility {
  slug
	name
	metadata {
		hero {
			url
		}
	}
}`);
